import React from 'react';
import './App.css';
import { useState , useEffect, createContext, useCallback} from "react";
import { fetchUserAttributes, fetchAuthSession, AuthSession, JWT} from 'aws-amplify/auth';
import { FetchUserAttributesOutput } from 'aws-amplify/auth';
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import {withAuthenticator} from '@aws-amplify/ui-react';
import {useAuthenticator,Authenticator} from '@aws-amplify/ui-react';
import {BrowserRouter as Router,Routes,Route, useLocation} from 'react-router-dom';
import {debounce} from "lodash";
import { useNavigate } from 'react-router-dom';
import Profile from './components/profile';
import Prices from './components/prices';
import Home from './components/home';
import NotFound from './components/notfound';
import '@aws-amplify/ui-react/styles.css';
import {Amplify} from 'aws-amplify';
import config from './aws-exports';
import '@radix-ui/themes/styles.css';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';
import { toast,Toaster } from 'react-hot-toast';
import { User } from './models/user';
import Community from './components/community';
import Login from './components/login';
import Events from './components/events';
import { Event, EventStatus, Winner, Submission } from './models/event';
import Landing from './components/landing';


export const UserContext = createContext<any>({});

Amplify.configure(config);

const isAdminUser = (token: JWT | undefined) => {
  return Array.isArray(token?.payload['cognito:groups']) && 
         token?.payload['cognito:groups'].includes('admin');
};

const AdminRoute = ({ children }: { children: React.ReactNode }) => {
  const { token } = React.useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const isAdmin = token?.payload['cognito:groups']?.includes('admin');
    if (!isAdmin) {
      navigate('/');
    }
  }, [token, navigate]);

  return token?.payload['cognito:groups']?.includes('admin') ? <>{children}</> : null;
};

// Add this new component for protected routes
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (authStatus !== 'authenticated') {
      // If trying to access /home, redirect to landing page
      if (location.pathname === '/home') {
        navigate('/');
      } else {
        // For all other protected routes, redirect to login
        navigate('/login');
      }
    }
  }, [authStatus, navigate, location]);

  return authStatus === 'authenticated' ? <>{children}</> : null;
};

// Add this new component for the login route
const LoginRoute = ({ children }: { children: React.ReactNode }) => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const navigate = useNavigate();

  useEffect(() => {
    if (authStatus === 'authenticated') {
      navigate('/home');
    }
  }, [authStatus, navigate]);

  return authStatus !== 'authenticated' ? <>{children}</> : null;
};

// Add this new component for the landing route
const LandingRoute = ({ children }: { children: React.ReactNode }) => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const navigate = useNavigate();

  useEffect(() => {
    if (authStatus === 'authenticated') {
      navigate('/home');
    }
  }, [authStatus, navigate]);

  return authStatus !== 'authenticated' ? <>{children}</> : null;
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const { authStatus, signOut: amplifySignOut } = useAuthenticator((context) => [context.authStatus]);
  const navigate = useNavigate();
  const [attributes, setAttributes] = useState<FetchUserAttributesOutput>();
  const [token, setToken] = useState<JWT>();
  const [user, setUser] = useState<User | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [submissions, setSubmissions] = useState<Submission[]>([]);

  console.log('API URL:', `${process.env.REACT_APP_URL}/user`);
  // Getting user data from cognito
  useEffect(() => {
    async function handleFetchUserAttributes() {
      try {
        setAttributes(await fetchUserAttributes());
        const result = (await fetchAuthSession()).tokens?.idToken;
        setToken(result);
      } catch (error) {
        toast.error("error");
      }
    }
    handleFetchUserAttributes();
  }, [authStatus === 'authenticated']);

  // Token refresh
  useEffect(() => {
    const tokenRefresh = setInterval(async () => {
      const result = (await fetchAuthSession()).tokens?.idToken;
      setToken(result);
    }, 1200000);
    return () => clearInterval(tokenRefresh);
  }, [authStatus === 'authenticated']);

  // Getting user data from dynamo db
  useEffect(() => {
    async function handleDynamoUser() {
      try {
        if (token) {
          const res = await axios.post(`${process.env.REACT_APP_URL}/user`, {
            email:  token?.payload.email,
            userId: token?.payload['cognito:username']
            },
            { headers: { "Authorization": token.toString()} }
          )
            setUser(u => ({
              ...u, 
              cap: res.data.body.cap,
              subscription: res.data.body.subscription,
              sign_up_date: res.data.body.sign_up_date,
              works: res.data.body.works
            }));
            setLoading(true);
        }
      } catch (error) {
        toast.error("error");
      }
    }
    handleDynamoUser();
  }, [token, authStatus === 'authenticated']);

  useEffect(() => {
    async function handleAdminEvents() {
      try {
        if (isAdminUser(token)) {
          const res = await axios.post(`${process.env.REACT_APP_URL}/adminData`,{},
            { headers: { "Authorization": token?.toString()} }
          )
          setSubmissions(res.data.body.submissions);
          setEvents(res.data.body.events);
        }
      } catch (error) {
        toast.error("error");
      }
    }
    handleAdminEvents();
  }, [token, authStatus === 'authenticated']);
  
 
  useEffect(() => {
    async function handleCommunityEvents() {
      try {
        if (token) {
          const res = await axios.post(`${process.env.REACT_APP_URL}/communityData`,{},
            { headers: { "Authorization": token.toString()} }
          )
          setEvents(res.data.body.events);
        }
      } catch (error) {
        toast.error("error");
      }
    }
    handleCommunityEvents();
  }, [token, authStatus === 'authenticated']);

  

  interface CacheData {
    M: string;
    T: string;
    G: string;
    CQ: string;
    SUM: string;
    S1: string;
    S2: string;
    S3: string;
    S4: string;
    S5: string;
    S6: string;
    S7: string;
    S8: string;
    S9: string;
  }
  
  const CACHE_KEY = 'cache_data';
  
  const initialData: CacheData = {
    M: '',
    T: '',
    G: '',
    CQ: '',
    SUM: '',
    S1: '',
    S2: '',
    S3: '',
    S4: '',
    S5: '',
    S6: '',
    S7: '',
    S8: '',
    S9: '',
  };
  const [data, setData] = useState<CacheData>(() => {
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      try {
        return JSON.parse(cachedData);
      } catch (error) {
        return initialData;
      }
    }
    return initialData;
  });

  // Create a memoized debounced function
  const debouncedSave = useCallback(
    debounce((newData: CacheData) => {
      try {
        localStorage.setItem(CACHE_KEY, JSON.stringify(newData));
      } catch (error) {
      }
    }, 500),
    []
  );
  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  // Create a wrapped signOut function that navigates after signing out
  const handleSignOut = async () => {
    try {
      await amplifySignOut();
      navigate('/');
    } catch (error) {
      toast.error("Error signing out");
    }
  };

  return (
    <>
      <Authenticator.Provider>
        <UserContext.Provider value={{
          attributes, 
          user, 
          setUser, 
          signOut: handleSignOut,
          token, 
          setToken, 
          loading, 
          data, 
          setData, 
          debouncedSave, 
          events, 
          setEvents, 
          submissions, 
          setSubmissions
        }}>
          <Routes>
            {/* Public routes */}
            <Route path="/" element={
              <LandingRoute>
                <Landing />
              </LandingRoute>
            } />
            <Route path="/login" element={
              <LoginRoute>
                <Login />
              </LoginRoute>
            } />
            
            {/* Protected routes */}
            <Route path="/home" element={
              <ProtectedRoute>
                <Home attributes={attributes} user={user} setUser={setUser} signOut={handleSignOut} />
              </ProtectedRoute>
            } />
            <Route path="/profile" element={
              <ProtectedRoute>
                <Profile attributes={attributes} user={user} setUser={setUser} />
              </ProtectedRoute>
            } />
            <Route path="/prices" element={
              <ProtectedRoute>
                <Prices attributes={attributes} user={user} signOut={handleSignOut} />
              </ProtectedRoute>
            } />
            <Route path="/community" element={
              <ProtectedRoute>
                <Community attributes={attributes} user={user} signOut={handleSignOut} />
              </ProtectedRoute>
            } />
            <Route path="/events" element={
              <ProtectedRoute>
                <AdminRoute>
                  <Events />
                </AdminRoute>
              </ProtectedRoute>
            } />
            
            {/* Catch-all route */}
            <Route path='*' element={<NotFound />} />
          </Routes>
        </UserContext.Provider>
      </Authenticator.Provider>
    </>
  );
}

export default App;
