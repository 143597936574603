import React, { useState } from 'react';
import { Flex, Text, TextArea as RadixTextArea, Button,Tooltip } from '@radix-ui/themes';

interface TextAreaProps {
  field: string;
  rows?: number;
  width?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onClearField: (field: string) => void;
  customLabels: { [key: string]: string };
  isHighlighted?: boolean;
  onToggleHighlight?: (field: string) => void;
  isHighlightable?: boolean;
  isDisabled?: boolean;
  isInternField?: boolean;

}

const TextArea: React.FC<TextAreaProps> = ({
    field,
    rows = 1,
    width = '100%',
    value,
    onChange,
    onClearField,
    customLabels,
    isHighlighted = false,
    onToggleHighlight,
    isHighlightable = false,
    isDisabled = false,
    isInternField = false
  }) => {
  const [flashState, setFlashState] = useState<'off' | 'firstFlash' | 'secondFlash'>('off');
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [flickerState, setFlickerState] = useState<'off' | 'on1' | 'off2' | 'on2'>('off');
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const tooltipContent = {
    "G": "A brief label indicating the type of story (e.g., Drama, Mystery, Sci-Fi), providing context for the story's style, tone, and conventions.",
    
    "SUM": "A concise description of the story's central plot and key events, capturing the core narrative in a few sentences.",
    
    "T": "The underlying message or idea explored in the story, such as love, redemption, or the struggle against corruption.",
    
    "CQ": "The primary question driving the story, often related to the protagonist's journey or conflict, e.g., \"Can one truly escape the past?\"",
    
    "M": "The emotional atmosphere of the story, setting expectations for how it feels to experience the narrative (e.g., tense, melancholic, hopeful).",
    
    "S1": "The opening scene or sequence that establishes the protagonist's everyday life, grounding the story before any major conflict arises.",
    
    "S2": "The event that disrupts the protagonist's life, setting the story's main conflict in motion and drawing the protagonist into action.",
    
    "S3": "A pivotal moment where the protagonist makes a decision or takes an action that commits them to the story's central journey, closing off the option to return to their former life.",
    
    "S4": "A pressure point that intensifies the conflict, often by revealing new information or escalating tension, reminding the protagonist of the stakes involved.",
    
    "S5": "A major turning point where the protagonist experiences a significant realization, shift in perspective, or confrontation that deepens their commitment to the goal or conflict.",
    
    "S6": "A critical challenge or obstacle that raises the stakes even higher, often bringing the protagonist to a low point or forcing them to confront their fears.",
    
    "S7": "The story's darkest moment, where all seems lost for the protagonist, intensifying the drama before the final push toward resolution.",
    
    "S8": "The peak of the story's action, where the main conflict reaches its most intense point and the protagonist faces their greatest challenge or decision.",
    
    "S9": "The story's conclusion, showing the outcome of the protagonist's journey and resolving any lingering questions or themes."
  } as const;

  const handleMouseMove = (event: React.MouseEvent<HTMLTextAreaElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setMousePosition({ x, y });
  };

  const handleFocus = () => {
    setIsFocused(true);
    
    setTimeout(() => {
      setFlickerState('on1');
      setTimeout(() => {
        setFlickerState('off2');
        setTimeout(() => {
          setFlickerState('on2');
        }, 100);
      }, 250);
    }, 0);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setFlickerState('off');
  };

  const handleClick = (e: React.MouseEvent) => {
    if (isHighlightable && onToggleHighlight) {
      e.preventDefault();
      onToggleHighlight(field);
    }
  };
  const getTextAreaStyle = () => {
    const baseStyle: React.CSSProperties = {
      width: '100%',
      border: '0.125rem solid rgba(0, 0, 0, 0.2)',
      borderRadius: '0.5rem',
      transition: 'all 0.05s ease-in-out',
      outline: 'none',
      WebkitTapHighlightColor: 'transparent',
      fontFamily: "'Courier', monospace",
      boxShadow: 'none',
      position: 'relative',
      zIndex: 2,
      cursor: isHighlightable ? 'pointer' : 'text',
      backgroundColor: 'transparent',
      pointerEvents: isHighlightable ? 'none' : 'auto',

    };

    const orangeColor = '#FF8C00';
    const orangeShadow = `0 0 0.625rem ${orangeColor}40, 0 0 1.25rem ${orangeColor}20, 0 0 1.875rem ${orangeColor}10`;

    if (isHovered || isFocused) {
      const { x, y } = mousePosition;
      const width = 300;
      const height = 150;
      
      const distanceX = Math.max(0, width - x);
      const distanceY = Math.max(0, height - y);
      
      const angle = Math.atan2(distanceY, distanceX);
      
      const shadowDistance = Math.min(Math.sqrt(distanceX * distanceX + distanceY * distanceY), 20);
      const shadowX = Math.cos(angle) * shadowDistance;
      const shadowY = Math.sin(angle) * shadowDistance;

      baseStyle.boxShadow = `
        ${shadowX}px ${shadowY}px 0.625rem rgba(0, 0, 0, 0.1),
        ${shadowX * 1.5}px ${shadowY * 1.5}px 1.25rem rgba(0, 0, 0, 0.05),
        ${shadowX * 2}px ${shadowY * 2}px 1.875rem rgba(0, 0, 0, 0.025)
      `;
    }

    if (isFocused && !isDisabled) {
      switch (flickerState) {
        case 'on1':
          baseStyle.border = `0.125rem solid ${orangeColor}`;
          baseStyle.boxShadow = 'none';
          break;
        case 'off2':
          baseStyle.border = `0.125rem solid transparent`;
          baseStyle.boxShadow = 'none';
          break;
        case 'on2':
          baseStyle.border = `0.125rem solid ${orangeColor}`;
          baseStyle.boxShadow = `${baseStyle.boxShadow}, ${orangeShadow}`;
          break;
        default:
          // Keep the default border
      }
    }

    return baseStyle;
  };

  const getHighlightStyle = (): React.CSSProperties => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '0.5rem',
    border: isHighlighted ? '2px solid #FF8C00' : 'none',
    opacity: isHighlighted ? .8 : 0,
    pointerEvents: 'none',
    transition: 'opacity 0.3s ease-in-out',
    backgroundColor: isHighlighted ? 'rgba(255, 140, 0, 0.05)' : 'transparent',
    zIndex: 1,
  });

  return (
    <Flex direction="row" className="text-area-container" style={{ width, position:'relative' }} onClick={handleClick}>
      <Flex direction="column" className="text-area" style={{ flex: 1 }}>
        <Tooltip content={tooltipContent[field as keyof typeof tooltipContent]}
        side="left" 
        align="start" 

        >
        <Text as="label" htmlFor={field} style={{ fontFamily: "'Courier', monospace" }}>
          {field.startsWith('S') ? customLabels[field] : 
           (field === 'G' ? 'Genre' : 
            field === 'T' ? 'Theme' : 
            field === 'M' ? 'Mood' : 
            field === 'CQ' ? 'Core Question' : 
            field === 'SUM' ? 'Summary' : 
            field)}
        </Text>
        </Tooltip>
        <div style={{ position: 'relative' }}>
          <div style={getHighlightStyle()} />
        <RadixTextArea       
          name={field}
          rows={rows}
          value={value}
          onChange={onChange}
          style={getTextAreaStyle()}
          onMouseMove={handleMouseMove}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        </div>
      </Flex>
      <Button 
        type="button" 
        variant="solid" 
        className="clear-button" 
        onClick={(e) => {
          e.stopPropagation();
          if (!isDisabled) {
            onClearField(field);
          }
        }}
        disabled={isDisabled}
      >
        <svg width="0.9375rem" height="0.9375rem" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.5 1C5.22386 1 5 1.22386 5 1.5C5 1.77614 5.22386 2 5.5 2H9.5C9.77614 2 10 1.77614 10 1.5C10 1.22386 9.77614 1 9.5 1H5.5ZM3 3.5C3 3.22386 3.22386 3 3.5 3H5H10H11.5C11.7761 3 12 3.22386 12 3.5C12 3.77614 11.7761 4 11.5 4H11V12C11 12.5523 10.5523 13 10 13H5C4.44772 13 4 12.5523 4 12V4L3.5 4C3.22386 4 3 3.77614 3 3.5ZM5 4H10V12H5V4Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"/>
        </svg>
      </Button>
    </Flex>
  );
};

export default TextArea;