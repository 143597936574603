import React, { useEffect,useState, useRef } from 'react';
import first5 from './first5.mp4';
import second5 from './second5.mp4';
import { Theme,Button,Dialog } from '@radix-ui/themes';
import {Authenticator, useAuthenticator} from '@aws-amplify/ui-react';

const dialogContentStyle = {
  maxWidth: 'fit-content',
  padding: 0,
  border: 'none',
}

function Login() {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const video1Ref = useRef<HTMLVideoElement>(null);
  const video2Ref = useRef<HTMLVideoElement>(null);
  const [signInOpen, setSignInOpen] = React.useState(false);
  const [getStartedOpen, setGetStartedOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const video1 = video1Ref.current;
    const video2 = video2Ref.current;

    if (!video1 || !video2 || authStatus === 'authenticated') {
      // Immediately stop videos if authenticated
      if (video1) {
        video1.pause();
        video1.currentTime = 0;
      }
      if (video2) {
        video2.pause();
        video2.currentTime = 0;
      }
      return;
    }

    // Start with video1 visible and video2 hidden
    video1.style.opacity = '1';
    video2.style.opacity = '0';

    const playSequence = () => {
      video1.currentTime = 0;
      const playPromise1 = video1.play();
      
      // Handle the play promise
      if (playPromise1 !== undefined) {
        playPromise1.catch(() => {
          // Ignore abort errors when video is stopped
        });
      }

      video1.onended = () => {
        video1.style.opacity = '0';
        video2.style.opacity = '1';
        video2.currentTime = 0;
        video2.loop = true;
        
        const playPromise2 = video2.play();
        if (playPromise2 !== undefined) {
          playPromise2.catch(() => {
            // Ignore abort errors when video is stopped
          });
        }

        video2.onended = null;
      };
    };

    playSequence();

    // Cleanup function
    return () => {
      if (video1) {
        video1.pause();
        video1.currentTime = 0;
        video1.onended = null;
      }
      if (video2) {
        video2.pause();
        video2.currentTime = 0;
        video2.onended = null;
      }
    };
  }, [authStatus]);

  // Styles for the container and videos
  const containerStyle: React.CSSProperties = {
    position: 'relative',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  };

  const videoStyle: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  // Styles for the buttons
  const buttonContainerStyle: React.CSSProperties = {
    position: 'absolute',
    bottom: '40px',
    left: '40px',
    zIndex: 10,
  };

  const signInButtonStyle: React.CSSProperties = {
    position: 'absolute',
    bottom: '40px',
    right: '40px',
    zIndex: 10,
  };

  return (
    <>
      {authStatus !== 'authenticated' && (
        <Theme>
          <div style={containerStyle}>
            <video
              ref={video1Ref}
              src={first5}
              muted
              playsInline
              preload="auto"
              style={{ ...videoStyle, opacity: 1 }}
            />
            <video
              ref={video2Ref}
              src={second5}
              muted
              playsInline
              preload="auto"
              style={{ ...videoStyle, opacity: 0 }}
            />
            <div style={buttonContainerStyle}>
              <Dialog.Root open={dialogOpen} onOpenChange={setDialogOpen}>
                <Dialog.Trigger>
                  <Button size="3" variant="soft">
                    Get Started
                  </Button>
                </Dialog.Trigger>
                <Dialog.Content style={dialogContentStyle}>
                  <Authenticator />
                </Dialog.Content>
              </Dialog.Root>
            </div>
            <div style={signInButtonStyle}>
              <Dialog.Root open={dialogOpen} onOpenChange={setDialogOpen}>
                <Dialog.Trigger>
                  <Button size="3" variant="soft">
                    Sign In
                  </Button>
                </Dialog.Trigger>
                <Dialog.Content style={dialogContentStyle}>
                  <Authenticator />
                </Dialog.Content>
              </Dialog.Root>
            </div>
          </div>
        </Theme>
      )}
    </>
  );
}

export default Login;
