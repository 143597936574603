
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './landing.css';
import LandingTitle from './FA New Test.png';
import YourImage from './UI Sample.png'; // Replace with the path to your image

// Import Feature Images
import FeatureImage1 from './Summary-Pic.png'; // Replace with your feature images
import FeatureImage2 from './Summary-Pic.png';
import FeatureImage3 from './Summary-Pic.png';
import FeatureImage4 from './Summary-Pic.png';
import BackgroundWide from './Background-WIDE.mp4';
// No image for Feature 5

const Landing: React.FC = () => {
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
      });
    }
  }, []);

  const features = [
    'Geared towards all levels of Writers',
    'What you create is *yours*',
    'Handcrafted training data',
    'Growing Community',
  ];

  // Define Feature Type with Discriminated Union
  type Feature =
    | {
        title: string;
        description: string;
        image: string;
        alignment: 'left' | 'right';
      }
    | {
        title: string;
        description: string;
        alignment: 'center';
      };

  // Features for the Third Section with Corresponding Images and Alignment
  const thirdSectionFeatures: Feature[] = [
    {
      title: 'Need a Spark? Test Endless Ideas',
      description:
        'Generate Strong, Detailed Story Summaries with models fine-tuned on handcrafted training data to spark your next vision.',
      image: FeatureImage1,
      alignment: 'left',
    },
    {
      title: 'Pieces of an Idea? Build it out from any direction',
      description:
        'Our models will fill in around any parts of your idea. Build around a shift at the midpoint, Start with your killer ending. The options are infinite.',
      image: FeatureImage2,
      alignment: 'right',
    },
    {
      title: 'Keeping Building',
      description:
        'Use the Intern to guide further generation and build further upon your outline and what the models generate with more precise guidance.',
      image: FeatureImage3,
      alignment: 'left',
    },
    {
      title: 'Win Prizes',
      description: 'Join the growing community and participate in our regular contests for CASH PRIZES.',
      image: FeatureImage4,
      alignment: 'right',
    },
    {
      title: 'Much, Much More on the Horizon',
      description:
        'There are numerous features in the works as we are committed to building this into the best AI Screenwriting Studio for writers.',
      alignment: 'center',
    },
  ];

  return (
    <div className="landing-page">
      {/* Hero Container */}
      <section className="hero-container">
        {/* Video Background */}
        <div className="video-background">
          <video
            ref={videoRef}
            autoPlay
            loop
            muted
            playsInline
            className="background-video"
          >
            <source src={BackgroundWide} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Black Overlay */}
        <div className="black-overlay" />

        {/* Content Wrapper */}
        <div className="content-wrapper">
          {/* Hero Section */}
          <section className="hero-section">
            {/* Hero Content (Left Side) */}
            <div className="hero-content">
              <img src={LandingTitle} alt="FilmAssistant.io" className="hero-logo" />

              <div className="hero-text-group">
                <p className="hero-text">
                  Need a spark or have a script that needs finishing touches? Build out your story from any
                  point—forward, backward, or anywhere in between. Save time on outlining and focus on making
                  it your story, unique and original.
                </p>

                <div className="features-list">
                  {features.map((feature, index) => (
                    <div key={index} className="feature-item">
                      {feature}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* CTA Wrapper */}
            <div className="hero-cta-wrapper">
              <div className="hero-cta">
                {/* "Join the Beta" Button with Film Grain Overlay */}
                <div className="btn-overlay-container">
                  <button onClick={() => navigate('/login')} className="btn btn-primary">
                    <span className="btn-text">Join the Beta</span>
                  </button>
                  {/* Film Grain Overlay */}
                  <video autoPlay loop muted playsInline className="btn-overlay">
                    <source src="/35mm_G3_DIRTY_2s.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                {/* "See Pricing" Button */}
                <button onClick={() => navigate('/prices')} className="btn btn-secondary">
                  See Pricing
                </button>
              </div>
            </div>
          </section>
        </div>
    
      </section>

      {/* Image Section */}
      <section className="image-section">
        <div className="image-container">
          <div className="image-wrapper">
            <img src={YourImage} alt="Descriptive Alt Text" className="centered-image" />
          </div>
          <div className="text-box">
            Rapidly Growing AI Screenwriting Studio for Writers of all Levels
          </div>
        </div>
      </section>

      {/* Third Section with Feature-Image Pairs */}
      <section className="third-section">
        <div className="third-section-content">
          <h2>Dive In!</h2>
          <div className="feature-image-pairs">
            {thirdSectionFeatures.map((feature, index) => (
              <div key={index} className="feature-image-pair">
                {feature.alignment === 'left' ? (
                  <>
                    <div className="feature-box">
                      <h3>{feature.title}</h3>
                      <p>{feature.description}</p>
                    </div>
                    <div className={`feature-image tilt-left`}>
                      <img src={feature.image} alt={`${feature.title} Image`} loading="lazy" />
                    </div>
                  </>
                ) : feature.alignment === 'right' ? (
                  <>
                    <div className={`feature-image tilt-right`}>
                      <img src={feature.image} alt={`${feature.title} Image`} loading="lazy" />
                    </div>
                    <div className="feature-box">
                      <h3>{feature.title}</h3>
                      <p>{feature.description}</p>
                    </div>
                  </>
                ) : (
                  // For center-aligned features without images
                  <div className="feature-box feature-box-center">
                    <h3>{feature.title}</h3>
                    <p>{feature.description}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Additional Sections */}
      <section className="additional-section">
        <div className="section-content">
          <h2>Additional Information</h2>
          <p>More details about your product or service.</p>
          <ul>
            <li>Additional Point One</li>
            <li>Additional Point Two</li>
            <li>Additional Point Three</li>
          </ul>
        </div>
      </section>


      {/* Fourth Section: Duplicate CTA Box */}
      <section className="fourth-section">
        <div className="fourth-section-content">
          {/* CTA Wrapper */}
          <div className="fourth-cta-wrapper">
            <div className="hero-cta">
              {/* "Join the Beta" Button with Film Grain Overlay */}
              <div className="btn-overlay-container">
                <button onClick={() => navigate('/login')} className="btn btn-primary">
                  <span className="btn-text">Join the Beta</span>
                </button>
                {/* Film Grain Overlay */}
                <video autoPlay loop muted playsInline className="btn-overlay">
                  <source src="/35mm_G3_DIRTY_2s.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              {/* "See Pricing" Button */}
              <button onClick={() => navigate('/prices')} className="btn btn-secondary">
                See Pricing
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landing;