import React from 'react';
import { Amplify } from 'aws-amplify';
import Header from './header';
import Footer from './footer';  
import {useDropzone} from 'react-dropzone';
import { useState , useEffect, useContext} from "react";
import {useForm} from "react-hook-form";
import { fetchUserAttributes } from 'aws-amplify/auth';
import { FetchUserAttributesOutput } from 'aws-amplify/auth';
import {updateUserAttribute,type UpdateUserAttributeOutput} from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import { useMutation } from "react-query";
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from '../aws-exports';
import { Theme, Flex, Text, Button, Grid, TextArea ,TextField,Box, Checkbox,ScrollArea} from '@radix-ui/themes';
import axios from 'axios';
import '@radix-ui/themes/styles.css';
import { DataList,Badge, } from '@radix-ui/themes';
import { ComponentPlaceholderIcon } from '@radix-ui/react-icons';
import { TailSpin } from 'react-loading-icons';
import {UserContext} from '../App';
import whiteOverlay from './Head-color with text.png';
import {toast,Toaster } from 'react-hot-toast';
import { User } from '../models/user';
import { Trash } from 'lucide-react';

import './profile.css';
import { useNavigate } from 'react-router-dom';
Amplify.configure(config);


export function Profile(props:any) {
  const {user, setUser, signOut, token, loading, data, setData,debouncedSave} = useContext(UserContext);
  const[changeUsername,setChangeUsername] = useState("");
  const[file, setFile] = useState<File | undefined>();
  const[preview, setPreview] = useState<string| ArrayBuffer | null>();
  const [set, setSet] = useState(new Set<string>());
  const [deletingItems, setDeletingItems] = useState(new Set<string>());
  const isDeleteSelectedDisabled = set.size === 0 || deletingItems.size > 0;
  const [hoveredStates, setHoveredStates] = useState<{[key: string]: boolean}>({});


  const handleMouseEnter = (key: string) => {
    setHoveredStates(prev => ({
      ...prev,
      [key]: true
    }));
  };

  const handleMouseLeave = (key: string) => {
    setHoveredStates(prev => ({
      ...prev,
      [key]: false
    }));
  };


   const selectAll = () => {
    if (user?.works) {
      const allKeys = Object.keys(user.works);
      setSet(new Set(allKeys));
    }
  };
  const clearAll = () => {
    setSet(new Set());
  };

  const addValue = (value: any) => {
    setSet(prevSet => new Set(prevSet.add(value)));
  };

  const removeValue = (value: any) => {
    setSet(prevSet => {
      const newSet = new Set(prevSet);
      newSet.delete(value);
      return newSet;
    });
  };

  const handleCheckboxChange = (key: string, isChecked: boolean) => {
    if (isChecked) {
      addValue(key);
    } else {
      removeValue(key);
    }
  };
  const navigate = useNavigate();

  //delete saved work
  const deleteWork = useMutation({
    mutationFn:(keys: Set<string> | string) => {
      return handleDeletion(keys);
      },
      onMutate: (keys) => {
        // Add keys to deletingItems when mutation starts
        if (keys instanceof Set) {
          setDeletingItems(new Set([...deletingItems, ...keys]));
        } else {
          setDeletingItems(new Set([...deletingItems, keys]));
        }
      },
      onSuccess: (res: any) => {
        if (res.data.statusCode == 200) {
          setSet(new Set());
          toast.success("Work deleted!");
          setUser((user: User) => ({...user, works: res.data.body.works}));
          setDeletingItems(new Set());
        } else if (res.data.statusCode == 400) {
          setSet(new Set());
          setDeletingItems(new Set());
          toast.error(res.data.body.error);
        } else {
          toast.error("error");
          setSet(new Set());
          setDeletingItems(new Set());
        }
      },
      onError: (error: any) => {
        toast.error("error");
        setSet(new Set());
        setDeletingItems(new Set());
      },
    });
  
  const handleDeletion = async(keys: Set<string> | string) => {
    const titlesToDelete = keys instanceof Set ? Array.from(keys) : [keys];
    return await axios.post(`${process.env.REACT_APP_URL}/works`, {
      "event": "delete",
      "titles": titlesToDelete,
      "userId": token?.payload['cognito:username'],
    }, 
    { headers: { "Authorization": token.toString()} }
  );
  }

  const deleteSelected = () => {
    if (set.size > 0) {
      deleteWork.mutateAsync(set);
    } else {
      toast.error("No works selected for deletion");
    }
  }

  const loadWork = (key: string) => {
    
    setData((data: any) => ({...data, ...user?.works[key]}));
    debouncedSave(user?.works[key]);
    navigate('/');
  }
  if (loading == false) {
    return (
      <>
        <div
          style={{
            position: 'relative',
            left: '50%',
            top: '18.75rem',
          }}
        >
      <TailSpin  stroke="#FFA500" speed="1.3" />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Theme>
          <Toaster position="top-center" reverseOrder={false} />
          <div className="app-container" style={{ paddingBottom: '3rem' }}>
            <div className="gradient-background"></div>
            <Header />
            <Flex direction="column" justify="center" align="center" style={{ flexGrow: 1, position: 'relative' }}>
              <div className="banner-container">
                <img src={whiteOverlay} className="white-overlay" alt="white-overlay" />
              </div>
              <Box className="white-container">
                <h2 style={{ textAlign: 'center' }}>Profile </h2>
                <DataList.Root>
                  <DataList.Item>
                    <DataList.Label>Username</DataList.Label>
                    <DataList.Value>{token?.payload?.preferred_username}</DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label>Email</DataList.Label>
                    <DataList.Value>{token?.payload?.email}</DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label>Tokens</DataList.Label>
                    <DataList.Value>{user?.cap}</DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label>Subscription</DataList.Label>
                    <DataList.Value>
                      <Badge color="jade" variant="soft" radius="full">
                        {user?.subscription}
                      </Badge>
                    </DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label>Sign up date</DataList.Label>
                    <DataList.Value>{user?.sign_up_date}</DataList.Value>
                  </DataList.Item>
                </DataList.Root>
              </Box>
              <ScrollArea
                type="hover"
                scrollbars="vertical"
                style={{
                  height: '50rem',
                  width: '50rem',
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  borderRadius: '1.5rem',
                  backdropFilter: 'blur(0.625rem)',
                  boxShadow: '0 0.75rem 1.5rem rgba(0, 0, 0, 0.2)',
                  border: '0.0625rem solid rgba(255, 255, 255, 0.3)',
                  margin: '1.5625rem 0',
                  zIndex: 2,
                  padding: '2rem',
                }}
              >
                <h2 style={{ textAlign: 'center' }}>Works</h2>
                <Flex direction="row" align="center" justify="end" gap="2" className="w-full"
                style={{
                  //position: 'relative',
                  marginBottom: '1rem',
                }}
                >
                  <div
                    style={{
                      visibility: user?.works && Object.keys(user.works).length === 0 ? 'hidden' : 'visible',
                      display: 'inline-flex',
                      gap: '0.75rem',
                    }}
                  >
                    <Button
                      onClick={selectAll}
                      variant="ghost"
                      size="3"
                      style={{ position: 'relative', right: '.625rem' }}
                    >
                      Select All
                    </Button>
                  </div>
                  <div
                    style={{
                      visibility: set.size === 0 ? 'hidden' : 'visible',
                      display: 'inline-flex',
                      gap: '0.75rem',
                    }}
                  >
                    <Button onClick={clearAll}>Clear All</Button>
                    <Button onClick={deleteSelected} disabled={isDeleteSelectedDisabled}>
                      Delete Selected
                    </Button>
                  </div>
                </Flex>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '1rem',
                    padding: '1rem',
                  }}
                >
                  {user?.works && Object.keys(user.works).length > 0 ? (
                    Object.keys(user.works).map(key => (
                      <Box className="white-container" 
                      key={key}
                      style={{ 
                        margin: 0,
                        position: 'relative',
                      }}
                      >
                      <div style={{ height: '100%' }}>
                      <Flex align="center" justify="between" style={{ marginBottom: '0.5rem' }}>
                        <Flex align="center" gap="2">
                          <Checkbox
                            size="2"
                            checked={set.has(key)}
                            onCheckedChange={e => handleCheckboxChange(key, e as boolean)}
                          />
                          <Text 
                            as="label" 
                            style={{ 
                              fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
                              fontWeight: '500',
                              fontSize: '1.2rem',
                              color: '#1A1A1A'
                            }}
                          >
                            {key}
                          </Text>
                        </Flex>
                        <Button
                          size="1"
                          style={{
                            marginBlockEnd: '',
                          }}
                          onClick={() => deleteWork.mutateAsync(key)}
                          disabled={deletingItems.has(key)}
                        >
                          <Trash style={{ height: '0.75rem', width: '0.75rem' }} />
                        </Button>
                      </Flex>

                    <div
                      onClick={() => loadWork(key)}
                      onMouseEnter={() => handleMouseEnter(key)}
                      onMouseLeave={() => handleMouseLeave(key)}
                      style={{
                        width: '100%',
                        border: `0.125rem solid ${
                          hoveredStates[key] ? 'orange' : 'rgba(0, 0, 0, 0.1)'
                        }`,
                        borderRadius: '0.75rem',
                        padding: '1rem',
                        transition: 'all 0.2s ease-in-out',
                        outline: 'none',
                        WebkitTapHighlightColor: 'black',
                        cursor: 'pointer',
                        minHeight: hoveredStates[key] ? '12rem' : '4rem',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: hoveredStates[key] ? 'rgba(255, 255, 255, 0.9)' : 'rgba(255, 255, 255, 0.7)',
                        boxShadow: hoveredStates[key]
                          ? '0 0.5rem 1rem rgba(255, 140, 0, 0.1), 0 0 0 2px rgba(255, 140, 0, 0.2)'
                          : '0 0.25rem 0.5rem rgba(0, 0, 0, 0.05)',
                        transform: hoveredStates[key] ? 'translateY(-2px)' : 'translateY(0)',
                      }}
                    >
                      <Flex direction="column" gap="2">
                        <Badge variant="soft" color="orange" style={{ width: 'fit-content' }}>
                          {user.works[key]?.G}
                        </Badge>
                        <div style={{
                          maxHeight: hoveredStates[key] ? '200px' : '0',
                          overflow: 'hidden',
                          transition: 'all 0.3s ease-in-out',
                          opacity: hoveredStates[key] ? 1 : 0,
                        }}>
                          <Text style={{ 
                            fontSize: '0.9rem', 
                            lineHeight: '1.5',
                            color: '#333',
                            fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
                          }}>
                            {user.works[key]?.SUM}
                          </Text>
                        </div>
                      </Flex>
                    </div>
                  </div>
                </Box>
                ))
              ) : (
                <Text style={{ textAlign: 'center', width: '100%', gridColumn: '1 / -1' }}>
                  No works found
                </Text>
              )}
                </div>
              </ScrollArea>
            </Flex>
          </div>
          <Footer />
        </Theme>
      </>
    );
  }
}

export default Profile;

