import React from 'react';
import { Amplify } from 'aws-amplify';
import Header from './header';
import Footer from './footer';  
import {useDropzone} from 'react-dropzone';
import { useState , useEffect, useContext} from "react";
import {useForm} from "react-hook-form";
import { fetchUserAttributes } from 'aws-amplify/auth';
import { FetchUserAttributesOutput } from 'aws-amplify/auth';
import {updateUserAttribute,type UpdateUserAttributeOutput} from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import { useMutation } from "react-query";
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from '../aws-exports';
import { Theme, Flex, Text, Button, Grid, TextArea ,TextField,Box, Checkbox, ScrollArea,Dialog} from '@radix-ui/themes';
import axios from 'axios';
import '@radix-ui/themes/styles.css';
import { DataList,Badge, } from '@radix-ui/themes';
import { ComponentPlaceholderIcon } from '@radix-ui/react-icons';
import { TailSpin } from 'react-loading-icons';
import {UserContext} from '../App';
import whiteOverlay from './Head-color with text.png';
import {toast,Toaster } from 'react-hot-toast';
import { User } from '../models/user';
import { Trash } from 'lucide-react';
import { Event, EventStatus, Winner } from '../models/event';

import './profile.css';
import { useNavigate } from 'react-router-dom';
Amplify.configure(config);


export function Community(props:any) {
  const {attributes,user, setUser, signOut, token, loading, data, setData,debouncedSave,events,setEvents} = useContext(UserContext);
  const[changeUsername,setChangeUsername] = useState("");
  const[file, setFile] = useState<File | undefined>();
  const[preview, setPreview] = useState<string| ArrayBuffer | null>();
  const [set, setSet] = useState(new Set<string>());
  const [deletingItems, setDeletingItems] = useState(new Set<string>());
  const isDeleteSelectedDisabled = set.size === 0 || deletingItems.size > 0;
  const [hoveredStates, setHoveredStates] = useState<{[key: string]: boolean}>({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const isMember = user?.subscription === 'member';


  const handleSubmitWork = (workKey: string) => {
    // Handle the submit action for the selected work
    mutateSubmitWork.mutateAsync(workKey);
    // Add your submission logic here
  };

  const mutateSubmitWork = useMutation({
    mutationFn: (workKey: string) => {
      return axios.post(`${process.env.REACT_APP_URL}/community`, {
        "userId": token?.payload['cognito:username'],
        "username": token?.payload?.preferred_username,
        "email": token?.payload?.email,
        "event": "submit",
        "title": workKey,
        "story": user?.works[workKey],
        "eventId": events[0].id
      },
      { headers: { "Authorization": token.toString()} }
      );
    },
    onSuccess: (res: any) => {
      if (res.data.statusCode == 200) {
        toast.success("work submited!"); 
        setDialogOpen(false);
      } else if (res.data.statusCode == 400) {
        toast.error(res.data.body.error);
      } else {
        toast.error("error");
      }
    },
    onError: (error: any) => {
      toast.error("error");
    },
  });


  if (loading == false) {
    return (
      <>
        <div
        style={{
          position: 'relative',
          left:'50%',
          top:'18.75rem',
        }}   
        >
      <TailSpin  stroke="#FFA500" speed="1.3" />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Theme>
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
          <div className="app-container" style={{ paddingBottom: '3rem' }}>
          <div className="gradient-background"></div>
            <Header/>
            <Flex direction="column" justify="center" align="center" style={{ flexGrow: 1, position: 'relative' }}>
            < div className= "banner-container">
            <img src={whiteOverlay} className="white-overlay" alt="white-overlay" />
            </div>
            <Flex direction="row" justify="center" align="center" > 
                <ScrollArea type="hover" scrollbars="vertical" style={{ height:'50rem', width:'40rem', backgroundColor: 'rgba(255, 255, 255, 0.2)',
                            borderRadius: '1.5rem',
                            padding: '2rem',
                            paddingTop: '2.5rem',
                            backdropFilter: 'blur(0.625rem)',
                            boxShadow: '0 0.75rem 1.5rem rgba(0, 0, 0, 0.2)',
                            border: '0.0625rem solid rgba(255, 255, 255, 0.3)',
                            margin: '1.5625rem 0',
                            zIndex: 2,}}>
                        <Flex direction="column" justify="center" align="center" > 
                        {events[0]?.status === "OPEN" && (
                        <Box className="white-container"
                            style={{
                                height: '15rem' , width: '25rem', display: 'flex', flexDirection: 'column',alignItems: 'center', position: 'relative'
                                }}>
                            <h3 style={{ textAlign: 'center' }}>{events[0].title} - {new Date(events[0].date).toLocaleDateString('en-US', {
                                    month: 'numeric',
                                    day: 'numeric',
                                    year: 'numeric'
                                })}</h3>
                            <Text style={{
                                    position: 'absolute',
                                    textAlign: 'center',
                                    marginTop: '1rem',
                                    marginLeft: '1rem',
                                    marginRight: '1rem',
                                    bottom: '6rem'
                                }}>
                                    Submit your best story of the Week for a chance to win ${events[0].prize}
                                </Text>
                                <Flex 
                                    justify="center" 
                                    align="center"
                                    style={{
                                         position: 'absolute',  // Position absolutely
                                        bottom: '1.5rem',     // Distance from bottom
                                        left: 0,              // Stretch across full width
                                        right: 0  
                                    }}>
                                <Dialog.Root open={dialogOpen} onOpenChange={setDialogOpen}>
                                    <Dialog.Trigger>
                                        <Button 
                                            disabled={!isMember} 
                                            style={{
                                                cursor: !isMember ? 'not-allowed' : 'pointer'
                                            }}
                                        > 
                                            {!isMember ? 'Membership Required' : 'Submit Story'}
                                        </Button>
                                    </Dialog.Trigger>
                                    <Dialog.Content 
                                        style={{
                                            height: '20rem',
                                            width: '25rem'
                                        }}>
                                            <Dialog.Title style={{textAlign: 'center'}}>Submit Work</Dialog.Title>
                                            <Grid 
                                                columns='5'
                                                gap="2"
                                                style={{
                                                    gridTemplateColumns: 'repeat(3, 7em)',
                                                    gridAutoRows: '6rem'
                                                    
                                                }}
                                            >
                                                {user?.works && Object.keys(user?.works).map(key => (
                                                    <Box 
                                                        key={key} 
                                                        style={{
                                                            height: '6rem',
                                                            width: '6rem',
                                                            backgroundColor: 'white',
                                                            borderRadius: '0.5rem',
                                                            padding: '0.5rem',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            boxShadow: '0 0.125rem 0.25rem rgba(0,0,0,0.1)',
                                                            border: '0.0625rem solid #e0e0e0',  // Added grey outline
                                                            marginBottom: '5rem'   
                                                        }}
                                                    >
                                                        <Text size="2" style={{ textAlign: 'center' }}>
                                                            {key}
                                                        </Text>
                                                        <Button size="1" onClick={() => handleSubmitWork(key)}>
                                                            Submit
                                                        </Button>
                                                    </Box>
                                                ))}
                                            </Grid>
                                            <Flex gap="3" mt="4" justify="end">
                                            <Dialog.Close>
                                                <Button variant="soft">
                                                    Close
                                                </Button>
                                            </Dialog.Close>
                                        </Flex>
                                    </Dialog.Content>
                                </Dialog.Root>   
                            </Flex>
                            </Box>)}
                            {events.filter((event: Event) => event.status !== "OPEN").map((event: Event) => (
                                <Box className="white-container"
                                style={{
                                    height: '15rem' , width: '25rem', display: 'flex', flexDirection: 'column',alignItems: 'center', position: 'relative'
                                    }}>
                                <h3 style={{ textAlign: 'center' }}>{event.title} - {new Date(event.date).toLocaleDateString('en-US', {
                                          month: 'numeric',
                                          day: 'numeric',
                                          year: 'numeric'
                                      })}</h3>
                                <Text style={{
                                        position: 'absolute',
                                        textAlign: 'center',
                                        marginTop: '1rem',
                                        marginLeft: '1rem',
                                        marginRight: '1rem',
                                        bottom: '6.5rem'
                                    }}>
                                        View {event.winner.username} story here
                                    </Text>
                                    <Dialog.Root>
                                        <Dialog.Trigger>
                                            <Button 
                                                disabled={!isMember}
                                                style={{
                                                    position: 'absolute',
                                                    bottom: '1.5rem',
                                                    cursor: !isMember ? 'not-allowed' : 'pointer'
                                                }}
                                            > 
                                                {!isMember ? 'Membership Required' : 'View Story'}
                                            </Button>
                                        </Dialog.Trigger>
                                        <Dialog.Content 
                                            style={{
                                                height: '50rem',
                                                width: '60rem'
                                            }}>
                                        
                                                <Dialog.Title style={{textAlign: 'center'}}>{event.winner?.title}</Dialog.Title>
                                                <Dialog.Description size="2" mb="4">
                                                        <>
                                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => {
                                                                const key = `S${num}` as keyof typeof event.winner.story;
                                                                return (
                                                                    event?.winner?.story?.[key] && (
                                                                        <React.Fragment key={num}>
                                                                            {event.winner.story[key]}
                                                                            <br /><br />
                                                                        </React.Fragment>
                                                                    )
                                                                );
                                                            })}
                                                        </>
                                                </Dialog.Description>
                                                <Flex gap="3" mt="4" justify="end">
                                                <Dialog.Close>
                                                    <Button variant="soft">
                                                        Close
                                                    </Button>
                                                </Dialog.Close>
                                            </Flex>                                    
                                        </Dialog.Content>
                                    </Dialog.Root>
                                    </Box>
                            ))}
                        </Flex>
                </ScrollArea>
            </Flex>
            </Flex>
            </div>
        <Footer/>
      </Theme>
      </>
    );
  }
}


export default Community;

